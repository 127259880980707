.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: center;
}
.pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
}

.disabled > a{
    color: #777 !important;
}

.pagination > li > a{
    border: 1px solid #dee2e6;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > li > a:hover{
    color: #777;
}

.active > a:hover{
    color: white !important;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #999;
    border-color: #777;
    outline: none ;
}
.pagination > li > a, .pagination > li > span{
    color: #007bff
}

.pagination > li:first-child > a, .pagination > li:first-child > span{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.pagination > li:last-child > a, .pagination > li:last-child > span{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}