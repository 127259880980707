.splashContainer{
    padding-top: 40px;
    padding-bottom: 40px;
    color: #71748d;
    background-position-x: left;
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
}

.card{
    width: 100%;
    max-width: 375px;
    /* padding: 15px; */
    margin: auto;
    margin-bottom: 30px;
    border: 1px solid #eee;
    border-radius: 5px;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    background-color: white;
}

.RegitserWidth{
    max-width: 500px;
}

.splashDescription{
    text-align: center;
    display: block;
    line-height: 20px;
    font-size: 1rem;
    margin-top: 5px;
    padding-bottom: 10px;
}

.cardFooterItemLeft{
    border-right: 1px solid rgb(230, 230, 242);
    padding: 12px 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 50%;
    text-align: center;
    display: inline-block;
    font-size: 0.8rem;
}

.cardFooterItemRight{
    padding: 12px 0;
    width: 50%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    display: inline-block;
    font-size: 0.8rem;
}

.Link{
    text-decoration: none;
    color: #71748d;
}

.Logo{
    width: 40px;
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    padding: 3px;
    background-color: #965ba5;
    border-radius: 5px;
}

.hasError{
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}
