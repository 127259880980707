@font-face {
  font-family: 'Lucida Console';
  src: local('Lucida Console'), url(./assets/fonts/LucidaConsole.woff) format('woff');
}
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.Item{
    transition: transform .2s;
}

.Item:hover {
  transform: scale(1.005);
  z-index: 1;
  -webkit-box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
  -moz-box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
  box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
}

.SingleImageUploadPanel {
  border-radius: 5px;
  width: 80%;
  /*margin: 0 auto;*/
  max-width: 467px;
  text-align: center;
  margin-top: 20px;
  background-size: 15px 30px;
  /*box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);*/

}

