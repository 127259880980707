.nav-item{
    margin: 0 10px;
}

.nav-item .active{
    font-weight: 700;
    background-color: white;
    color: #444 !important;
    border-radius: 5px;
}

@media screen and (max-width: 992px) {
    .nav-link {
        margin-top: 5px;
        padding-left: 10px !important;
    }
}
