@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Lucida Console';
  src: local('Lucida Console'), url(/static/media/LucidaConsole.720118a8.woff) format('woff');
}
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.Item{
    transition: transform .2s;
}

.Item:hover {
  transform: scale(1.005);
  z-index: 1;
  box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
}

.SingleImageUploadPanel {
  border-radius: 5px;
  width: 80%;
  /*margin: 0 auto;*/
  max-width: 467px;
  text-align: center;
  margin-top: 20px;
  background-size: 15px 30px;
  /*box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);*/

}


body {
    font-family: 'Poppins', sans-serif;
}

.Dashboard_logo__2bcPP{
    display: block;
    width: 30px;
    margin: 0 auto;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    transition: all 0.3s;
}

.Dashboard_navbar__Jmr_g {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.Dashboard_wrapper__1sc3J {
    display: flex;
    width: 100%;
    align-items: stretch;
    background-color: #fafafa;
}

.Dashboard_sidebar__30MhR {
    min-width: 250px;
    max-width: 250px;
    background: #e74c3c;
    color: #fff;
    transition: all 0.3s;
}

.Dashboard_components__39zlF {
    padding: 10px 0;
    border-bottom: 1px solid white;
}

.Dashboard_categoryComponents__3MY92 {
    /*padding: 10px 0;*/

    list-style: none;
    border-bottom: 1px solid white;
}

.Dashboard_sidebarHeader__2LHYh {
    background: #ca1d0b;
    font-size: 1.3em;
    padding: 10px;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.Dashboard_sidebarHeader__2LHYh h3{
    cursor: pointer;
    color: #fff;
}

.Dashboard_sidebarHeader__2LHYh a:hover{
    text-decoration: none;
}

ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: white;
    font-weight: 600;
}

.Dashboard_sidebar__30MhR ul li a:hover {
    color: #e74c3c;
    background: #fff;
    text-decoration: none;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.Dashboard_active__2kg83 {
    color: #e74c3c;
    background: white;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.Dashboard_categoryActive__29_m4 {
    color: white;
    background: #e74c3c;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.Dashboard_categoryNavLink__nFUJI {
    color: #e74c3c;
    background: white;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

ul ul{
    padding: 0;
}

ul ul li {
    font-size: 0.9em !important;
    list-style-type:none;
}

ul ul li a{
    padding-left: 30px !important;
}

.Dashboard_content__2LOk_ {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    box-shadow: -5px 1px 12px rgba(0, 0, 0, 0.5);
}

/*UNUSED*/


.Dashboard_CTAs__gCwBD {
    padding: 20px;
}

.Dashboard_CTAsA__1Njp1 {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

.Dashboard_navLink__2_HdW{
    padding-right: 15px !important;
}

/*MEDIAQUERIES*/

@media (max-width: 768px) {
    .Dashboard_sidebar__30MhR {
        margin-left: -250px;
    }
    /*.sidebar .active {*/
    /*    margin-left: 0;*/
    /*}*/
    .Dashboard_sidebarCollapseSpan__2_-S0 {
        display: none;
    }

    .Dashboard_content__2LOk_ {
        margin-right: -250px;
    }
}
.Pagination_pagination__2guDI {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: center;
}
.Pagination_pagination__2guDI > .Pagination_active__1zUBA > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
}

.Pagination_disabled__lMNRm > a{
    color: #777 !important;
}

.Pagination_pagination__2guDI > li > a{
    border: 1px solid #dee2e6;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.Pagination_pagination__2guDI > li > a:hover{
    color: #777;
}

.Pagination_active__1zUBA > a:hover{
    color: white !important;
}

.Pagination_pagination__2guDI > .Pagination_active__1zUBA > a, .Pagination_pagination__2guDI > .Pagination_active__1zUBA > span, .Pagination_pagination__2guDI > .Pagination_active__1zUBA > a:hover, .Pagination_pagination__2guDI > .Pagination_active__1zUBA > span:hover, .Pagination_pagination__2guDI > .Pagination_active__1zUBA > a:focus, .Pagination_pagination__2guDI > .Pagination_active__1zUBA > span:focus{
    background-color: #999;
    border-color: #777;
    outline: none ;
}
.Pagination_pagination__2guDI > li > a, .Pagination_pagination__2guDI > li > span{
    color: #007bff
}

.Pagination_pagination__2guDI > li:first-child > a, .Pagination_pagination__2guDI > li:first-child > span{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.Pagination_pagination__2guDI > li:last-child > a, .Pagination_pagination__2guDI > li:last-child > span{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}
.artist_clickableTableHeader__3voRi {
    cursor: pointer;
}

.artist_hasError__2VgIX{
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}
.productImageSmallThumbnail_Tabs__2yDtE {
    color: #777 !important;
    border-radius: 0 !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
.editorClassName{
    border: 1px solid #ccc !important;
    border-radius: 5px;
    padding: 10px;
}
.productScreen_selector__ixP45{
    padding: 5px;
    margin: 0 4px;
    flex-direction: column;
    display: flex;
    align-items: center;
    transition: transform .2s;
}

.productScreen_selector__ixP45:hover{
    border-radius: 5px;
    border: 1px solid #aaa;
    transform: scale(1.2);
    z-index: 1;
    box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
}

[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
/* IMAGE STYLES */
[type=radio] + .productScreen_selector__ixP45{
    cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked + .productScreen_selector__ixP45{
    border-radius: 5px;
    border: 1px solid #aaa;
    transform: scale(1.2);
    z-index: 1;
    box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
}

.LoginContainer_splashContainer__bc5DQ{
    padding-top: 40px;
    padding-bottom: 40px;
    color: #71748d;
    background-position-x: left;
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
}

.LoginContainer_card__2qYXZ{
    width: 100%;
    max-width: 375px;
    /* padding: 15px; */
    margin: auto;
    margin-bottom: 30px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    background-color: white;
}

.LoginContainer_RegitserWidth__2Neah{
    max-width: 500px;
}

.LoginContainer_splashDescription__3KKBJ{
    text-align: center;
    display: block;
    line-height: 20px;
    font-size: 1rem;
    margin-top: 5px;
    padding-bottom: 10px;
}

.LoginContainer_cardFooterItemLeft__3BBiC{
    border-right: 1px solid rgb(230, 230, 242);
    padding: 12px 0;
    flex: 1 1;
    width: 50%;
    text-align: center;
    display: inline-block;
    font-size: 0.8rem;
}

.LoginContainer_cardFooterItemRight__friWA{
    padding: 12px 0;
    width: 50%;
    flex: 1 1;
    text-align: center;
    display: inline-block;
    font-size: 0.8rem;
}

.LoginContainer_Link__GB5iA{
    text-decoration: none;
    color: #71748d;
}

.LoginContainer_Logo__2F4l0{
    width: 40px;
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    padding: 3px;
    background-color: #965ba5;
    border-radius: 5px;
}

.LoginContainer_hasError__xiSGf{
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}



.nav-item{
    margin: 0 10px;
}

.nav-item .active{
    font-weight: 700;
    background-color: white;
    color: #444 !important;
    border-radius: 5px;
}

@media screen and (max-width: 992px) {
    .nav-link {
        margin-top: 5px;
        padding-left: 10px !important;
    }
}

