@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
    font-family: 'Poppins', sans-serif;
}

.logo{
    display: block;
    width: 30px;
    margin: 0 auto;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    background-color: #fafafa;
}

.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #e74c3c;
    color: #fff;
    transition: all 0.3s;
}

.components {
    padding: 10px 0;
    border-bottom: 1px solid white;
}

.categoryComponents {
    /*padding: 10px 0;*/

    list-style: none;
    border-bottom: 1px solid white;
}

.sidebarHeader {
    background: #ca1d0b;
    font-size: 1.3em;
    padding: 10px;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.sidebarHeader h3{
    cursor: pointer;
    color: #fff;
}

.sidebarHeader a:hover{
    text-decoration: none;
}

ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: white;
    font-weight: 600;
}

.sidebar ul li a:hover {
    color: #e74c3c;
    background: #fff;
    text-decoration: none;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.active {
    color: #e74c3c;
    background: white;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.categoryActive {
    color: white;
    background: #e74c3c;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

.categoryNavLink {
    color: #e74c3c;
    background: white;
    box-shadow: -10px 1px 12px rgba(0, 0, 0, 0.5);
}

ul ul{
    padding: 0;
}

ul ul li {
    font-size: 0.9em !important;
    list-style-type:none;
}

ul ul li a{
    padding-left: 30px !important;
}

.content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    box-shadow: -5px 1px 12px rgba(0, 0, 0, 0.5);
}

/*UNUSED*/


.CTAs {
    padding: 20px;
}

.CTAsA {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

.navLink{
    padding-right: 15px !important;
}

/*MEDIAQUERIES*/

@media (max-width: 768px) {
    .sidebar {
        margin-left: -250px;
    }
    /*.sidebar .active {*/
    /*    margin-left: 0;*/
    /*}*/
    .sidebarCollapseSpan {
        display: none;
    }

    .content {
        margin-right: -250px;
    }
}