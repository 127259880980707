.selector{
    padding: 5px;
    margin: 0 4px;
    flex-direction: column;
    display: flex;
    align-items: center;
    transition: transform .2s;
}

.selector:hover{
    border-radius: 5px;
    border: 1px solid #aaa;
    transform: scale(1.2);
    z-index: 1;
    -webkit-box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
    box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
}

[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
/* IMAGE STYLES */
[type=radio] + .selector{
    cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked + .selector{
    border-radius: 5px;
    border: 1px solid #aaa;
    transform: scale(1.2);
    z-index: 1;
    -webkit-box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
    box-shadow: -1px 11px 21px 0px rgba(0,0,0,0.20);
}